<script>
var SHA256 = require("crypto-js/sha256");


export default {
  name: 'change_password',

  data() { return {
    waitForResponse: false,

    newData: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    }
  }},

  props: {
    userId: [String, Number]
  },

  computed: {
    isValid() {
      return this.newData.new_password == this.newData.confirm_password;
    }
  },

  methods: {
    UpdatePassword()
    {
      const data = {
        old_password:  SHA256(this.newData.old_password).toString(),
        new_password:  SHA256(this.newData.new_password).toString() 
      }

      this.waitForResponse = true;
      this.$api.post({ path: `users/${this.userId}:update-password`, data }, this.OnResponse);
    },

    OnResponse({response})
    {
      this.waitForResponse = false;

      if(response.status != "success") {
        return;
      }

      this.$msg.push("Ai schimbat parola cu succes.");
      this.newData = {};
    }
  }
};
</script>


<template>
  <form @submit.prevent="UpdatePassword" class="vertical-container">
    <div v-if="!$session.HasAccess(['manage_users'])" class="input-row">
      <label>Parola Veche </label>

      <input-box placeholder="Old password.." maxlength="30" type="password" v-model:value="newData.old_password" required="true" />
    </div>


    <div class="input-row">
      <label>Parola nouă </label>

      <input-box placeholder="New password.." maxlength="30" type="password" v-model:value="newData.new_password" invalid required="true" />
    </div>

    <div class="input-row">
      <label>Confirmă parola</label>
      <input-box placeholder="Repeat password.." maxlength="30" type="password" v-model:value="newData.confirm_password" :invalid="!isValid" required="true" />
    </div>

    <div class="input-row input-feedback" v-if="!isValid">
      <li>Parolele nu se potrivesc</li>
    </div>

    <div class="input-row" style="margin-top: 20px;">
      <l-button type="submit" :active="waitForResponse" class="blue border-btn">
        {{$lan('btn_save')}}
      </l-button>
    </div>
  </form>
</template>

<style scoped>
.input-feedback{
  font-size: small;
  color: indianred;
  padding: 5px;
}
</style>
